import {
  createSlice
} from 'redux-starter-kit'
import {
  getStateFromCookies
} from 'redux-cookies-middleware';
import _ from 'lodash';
import {
  login as seekerLogin,
  tokenLogin as seekerLoginViaToken,
  logout as seekerLogout,
  loginViaUrlApi,
  loginViaSSOApi,
  updateExplicitConsent,
  
} from '../apis/jobseeker';
import {
  login as recruiterLogin,
  logout as recruiterLogout,
  loginSso as recruiterLoginViaSso,
  loginSsoTokens as recruiterLoginSsoViaTokens,
} from '../apis/recruiter';
import {
  loginApi as adminLogin,
  logoutApi as adminLogout,
  actAsApi,
} from '../apis/admin';
import {
  ROLE,
  STATUS,
  LOGIN_METHOD,
} from '../constants/constants';
import size from 'lodash/size'
import get from 'lodash/get'

// state to persist in cookies
const cookiePathMapping = {
  'username': {
    name: 'username'
  },
  'rememberMe': {
    name: 'remember_me'
  },
  'serial': {
    name: 'serial'
  },
  'validationCode': {
    name: 'validation_code'
  },
  'token': {
    name: 'token'
  },
  "s3Token": {
    name: 's3Token'
  },
  "s3IdentityId": {
    name: 's3IdentityId'
  },
  role: {name: "role"},
  adminToken: { name: 'adminToken' },
  loginMethod: { name: 'loginMethod' },
  jobseekerCode: { name: 'jobseekerCode' },
  authenticationErrorCode: { name: 'authenticationErrorCode' },
  'extraInfo.code': { name: 'extraInfo.code' },
  'extraInfo.redirectUri': { name: 'extraInfo.redirectUri' },
  'extraInfo.datafeedUrl': { name: 'extraInfo.datafeedUrl' },
  'odicDomain': {name: 'odicDomain'}
};

let initialState = {
  username: '',
  token: '',
  rememberMe: false,
  role: ROLE.guest,
  serial: '',
  validationCode: '',
  authenticationInProgress: false,
  logoutInProgress: false,
  authenticationError: '',

  adminToken: null,
  actingAs: false,

  s3Token: null,
  s3IdentityId: null,

  loginMethod: LOGIN_METHOD.normal,
  jobseekerCode: null,
  authenticationErrorCode: 0,
  extraInfo: {
    code: '',
    redirectUri: '',
    datafeedUrl: '',
  },
  hasExplicitConsent: false,
  odicDomain: ""
};
initialState = {
  ...initialState,
  ..._.omitBy(getStateFromCookies({}, cookiePathMapping), _.isNil)
};

const authenticationSlice = createSlice({
  slice: 'authenticated',
  initialState: initialState,
  reducers: {
    toggleRememberMe(state, action) {
      state.rememberMe = !state.rememberMe;
    },
    onLoginStarted(state, action) {
      state.username = action.payload.username;
      state.rememberMe = action.payload.rememberMe;
      state.role = action.payload.role;
      state.validationCode = null;
      state.authenticationInProgress = true;
      state.loginMethod = LOGIN_METHOD.normal
      state.extraInfo = {
        code: '',
        redirectUri: '',
        datafeedUrl: '',
      }
    },
    onRoleChange(state, action) {
      state.role = action.payload.role;
    },
    onLoginSuccess(state, action) {
      const authenticationResponse = action.payload;
      state.token = authenticationResponse.token;
      state.s3Token = authenticationResponse?.s3_token;
      state.s3IdentityId = authenticationResponse?.s3_identityId;
      if (authenticationResponse.role === ROLE.recruiter && state.rememberMe) {
        state.validationCode = authenticationResponse.message ? authenticationResponse.message : null;
      }
      state.authenticationInProgress = false;
    },
    onLoginFailed(state, action) {
      const authenticationResponse = action.payload;
      state.authenticationError = authenticationResponse.message;
      state.authenticationInProgress = false;
      state.token = null;
    },
    onLogout(state, action) {
      state.token = null;
      state.username = null;
      state.validationCode = null;
      state.rememberMe = false;
      state.logoutInProgress = false;
      state.extraInfo = {
        code: '',
        redirectUri: '',
        datafeedUrl: '',
      }
      state.role = ROLE.guest
    },
    onLogoutStart(state, action) {
      state.logoutInProgress = true;
    },

    onActingAs( state, action ) {
      state.actingAs = true
      state.loginMethod = LOGIN_METHOD.actingAs
    },
    onActingAsSuccess( state, action ) {
      state.actingAs = true
      console.debug( { action } )
      if( size( action.payload.token ) === 0 ) {
        // Returning to admin page
        state.token = state.adminToken
        state.adminToken = null
        state.role = ROLE.admin
      } else {
        // Change to recruiter page
        state.adminToken = state.token
        state.token = action.payload.token
        state.role = ROLE.recruiter
      }
    },
    onActingAsFailed( state, action ) {
      state.actingAs = false
    },

    onLoggingInViaUrl( state, action ) {
      state.authenticationInProgress = true
      state.jobseekerCode = get( action, 'payload.jobseekerCode' )
      state.authenticationErrorCode = 0
      state.token = null
      state.extraInfo = {
        code: '',
        redirectUri: '',
        datafeedUrl: '',
      }
      state.loginMethod = LOGIN_METHOD.viaUrl
    },
    onLoggingInViaUrlSucceeded( state, action ) {
      state.authenticationInProgress = false
      state.authenticationErrorCode = 0
      state.token = get( action, 'payload.token' )
      state.s3Token = get( action, 'payload.s3_token' )
      state.s3IdentityId = get( action, 'payload.s3_identityId' )
      state.extraInfo = {
        code: get( action, 'payload.code' ),
        redirectUri: get( action, 'payload.redirect_uri' ),
        datafeedUrl: get( action, 'payload.datafeed_url' ),
      }
    },
    onLoggingInViaUrlFailed( state, action ) {
      state.authenticationInProgress = false
      state.authenticationErrorCode = get( action, 'payload.error_code' )
      state.extraInfo = {
        code: get( action, 'payload.code' ),
        redirectUri: get( action, 'payload.redirect_uri' ),
        datafeedUrl: get( action, 'payload.datafeed_url' ),
      }
    },

    setAuthenticationErrorCode( state, action ) {
      console.debug( { action } )
      state.authenticationErrorCode = get( action, 'payload' )
    },
    
    onLoggingInViaSSO( state, action ) {
      state.authenticationInProgress = true
      state.jobseekerCode = get( action, 'payload.code' )
      state.authenticationErrorCode = 0
      state.token = null
      state.extraInfo = {
        code: '',
        redirectUri: '',
        datafeedUrl: '',
      }
      state.loginMethod = LOGIN_METHOD.viaUrl
    },
    onLoggingInViaSSOSucceeded( state, action ) {
      state.authenticationInProgress = false
      state.authenticationErrorCode = 0
      state.token = get( action, 'payload.token' )
      state.s3Token = get( action, 'payload.s3_token' )
      state.s3IdentityId = get( action, 'payload.s3_identityId' )
      state.extraInfo = {
        code: get( action, 'payload.code' ),
        redirectUri: get( action, 'payload.redirect_uri' ),
        datafeedUrl: get( action, 'payload.datafeed_url' ),
      }
    },
    onLoggingInViaSSOFailed( state, action ) {
      state.authenticationInProgress = false
      state.authenticationErrorCode = get( action, 'payload.error_code' )
      state.extraInfo = {
        code: get( action, 'payload.code' ),
        redirectUri: get( action, 'payload.redirect_uri' ),
        datafeedUrl: get( action, 'payload.datafeed_url' ),
      }
    },
    onUpdateOdicDomain(state, action){
      state.odicDomain = action.payload
    },
    resetOdicDomain(state, action){
      state.odicDomain = ""
    }
  }
});

export const {
  onLoginStarted,
  onLoginSuccess,
  onLoginFailed,
  toggleRememberMe,
  onLogout,
  onLogoutStart,
  onActingAs,
  onRoleChange,
  onActingAsSuccess,
  onActingAsFailed,
  onLoggingInViaUrl,
  onLoggingInViaUrlSucceeded,
  onLoggingInViaUrlFailed,
  onLoggingInViaSSO,
  onLoggingInViaSSOSucceeded,
  onLoggingInViaSSOFailed,
  setAuthenticationErrorCode,
  onUpdateOdicDomain,
  resetOdicDomain,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;

export const login = (email, password, role, rememberMe, validationCode) => async dispatch => {
  try {
    let loginResponse = null;
    dispatch(onLoginStarted({
      username: email,
      rememberMe: rememberMe,
      role: role
    }));
    if (role === ROLE.jobseeker) {
      loginResponse = await seekerLogin(email, password)
    } else if (role === ROLE.recruiter) {
      const rememberMeToken = (validationCode && !password) ? validationCode : '';
      loginResponse = await recruiterLogin(email, password, rememberMeToken);
    } else if (role === ROLE.admin) {
      loginResponse = await adminLogin(email, password)
    }
    if (loginResponse) {
      loginResponse = loginResponse.obj;
      if (loginResponse.status === STATUS.success) {
        dispatch(onLoginSuccess(loginResponse));
        return {
          token: loginResponse.token,
          errorCode: loginResponse.error_code,
        };
      } else {
        dispatch(onLoginFailed(loginResponse));
        return {
          message: loginResponse.message,
          errorCode: loginResponse.error_code
        };
      }
    }
    return {
      message: "Login Error"
    };
  } catch (err) {
    dispatch(onLoginFailed({
      message: err.toString()
    }));
    return {
      message: "Login Error"
    };
  }
};

const loginPathConfigs = {
  recruiter: {
    login: '/recruiter/settings',
    logout: '/',
  },
}

export const loginSso = (email, claims, environment, accessToken, role, idP) => async dispatch => {
  try{
    console.log("SsoLogin: calling api: just before calling: email: ", claims, " environment: ", environment ," accessToken: ", accessToken, " idP: ", idP, " role: ", role)
    let loginResponse = null;

    let currentUserName = email
    if(
      "upn" in claims &&
      claims["upn"] !== ""
    ){
      currentUserName = claims["upn"]
    }
    claims = JSON.stringify(claims)
    dispatch(onLoginStarted({
      username: currentUserName,
      rememberMe: false,
      role: role
    }));
    if (role === ROLE.recruiter){
      loginResponse = await recruiterLoginViaSso(claims, environment, accessToken, idP).catch(error => console.error("SsoLogin: calling api: email: ", error))
    } else {
      return {
        message: "Unsupported Login Type: This feature is only available for recruiter accounts"
      }
    }
    if (loginResponse){
      console.log("SsoLogin: calling api: email: ", loginResponse)
      loginResponse = loginResponse.obj
      if (loginResponse.status === STATUS.success){
        dispatch(onLoginSuccess(loginResponse));
        return {
          token: loginResponse.token,
          authPath: loginPathConfigs[role].login
        }
      } else {
        dispatch(onLoginFailed(loginResponse));
        return {
          error_code: loginResponse.error_code,
          message: loginResponse.message,
          role: loginResponse.role,

        }
      }
    }
    // console.log("SsoLogin: calling api: email: bad coding")
    // return {
    //   message: "Login Error"
    // };
  } catch (err) {
    dispatch(onLoginFailed({
      message: err.toString()
    }));
    return {
      message: "Login Error"
    }
  }
}


export const loginSsoViaOpenIdTokens = (tenantConfigs, accessToken, idToken, role=ROLE.recruiter) => async dispatch => {
  try {

    dispatch(onLoginStarted({
      username: '',
      role: role,
    }))
    
    console.log("Auth System: loginSsoViaOpenIdTokens: tenantConfigs: ", tenantConfigs, " accessToken: ", accessToken, " idToken: ", idToken)

    if(typeof(tenantConfigs) != "string"){
      tenantConfigs = JSON.stringify(tenantConfigs)
    }
    let loginResponse = null;
    if (role === ROLE.recruiter){
      loginResponse = await recruiterLoginSsoViaTokens(tenantConfigs, accessToken, idToken).catch(error => console.error("SsoLogin: calling api: email: ", error))
    } else {
      return {
        message: "Unsupported Login Type: This feature is only available for recruiter accounts"
      }
    }
        
    if (loginResponse){
      console.log("SsoLogin: calling api: email: ", loginResponse)
      loginResponse = loginResponse.obj
      if (loginResponse.status === STATUS.success){
        dispatch(onLoginSuccess(loginResponse));
        return {
          token: loginResponse.token,
          authPath: loginPathConfigs[role].login
        }
      } else {
        dispatch(onLoginFailed(loginResponse));
        return {
          error_code: loginResponse.error_code,
          message: loginResponse.message,
          role: loginResponse.role,

        }
      }
    }    
  } catch (err) {
    dispatch(onLoginFailed({
      message: err.toString()
    }));
    return {
      message: "Login Error"
    }
  }
}

export const tokenLogin = (email, hashedInterviewId, token, role, rememberMe, urlHash) => async dispatch => {
  try{
    let loginResponse = null;
    dispatch(onLoginStarted({
      username: email,
      rememberMe: rememberMe,
      role: role
    }));
    if (role === ROLE.jobseeker) {
      loginResponse = await seekerLoginViaToken(hashedInterviewId, token, urlHash)
    }
    if (loginResponse) {
      loginResponse = loginResponse.obj
      if (loginResponse.status === STATUS.success){
        dispatch(onLoginSuccess(loginResponse));
        return {
          token: loginResponse.token,
          errorCode: loginResponse.error_code,
        };
      } else {
        dispatch(onLoginFailed(loginResponse));
        return {
          message: loginResponse.message,
          errorCode: loginResponse.error_code
        };
      };
    }
  } catch (err) {
    dispatch(onLoginFailed({
      message: err.toString()
    }));
    return {
      message: "Login Error"
    }
  }
}

export const logout = (token, role) => async dispatch => {
  try {
    dispatch(onLogoutStart());
    if (role === ROLE.jobseeker) {
      await seekerLogout(token)
    } else if (role === ROLE.recruiter) {
      await recruiterLogout(token)
    } else if (role === ROLE.admin) {
      await adminLogout(token)
    }
    return true;
  } catch (err) {console.error(err)} finally {
    dispatch(onLogout());
  }
};

export const softLogout = (token, invalidate_token = false, role) => async dispatch => {
  try {
    dispatch(onLogoutStart());
    // dispatch(disableRefreshTokenUpdateHook())
    if(invalidate_token === true){
      if (role === ROLE.jobseeker) {
        await seekerLogout(token)
      } else if (role === ROLE.recruiter) {
        await recruiterLogout(token)
      } else if (role === ROLE.admin) {
        await adminLogout(token)
      }
    }
    return true
  } catch (err) {console.error(err)} finally {
  }
};

export const loginViaUrl = jobseekerCode => async dispatch => {
  let ret = false
  try {
    dispatch( onLoggingInViaUrl( { jobseekerCode } ) )
    const response = await loginViaUrlApi( jobseekerCode )
    if( response && response.ok && response.obj ) {
      if( response.obj.error_code === 0 ) {
        dispatch( onLoggingInViaUrlSucceeded( response.obj ) )
        ret = true
      } else {
        dispatch( onLoggingInViaUrlFailed( response.obj ) )
      }
    }
  } catch( error ) {
    dispatch( onLoggingInViaUrlFailed( { message: error.toString() } ) )
  } finally {
    return ret
  }
}

export const loginViaSSO = jobseekerToken => async dispatch => {
  let ret = false
  try {
    dispatch(onLoggingInViaUrl({
      jobseekerToken
    }))
    const response = await loginViaSSOApi(jobseekerToken)
    console.log({
      response
    })
    const responseBody = response?.body
    if (response && response.ok && responseBody) {
      if (responseBody.status === "success") {
        dispatch(onLoggingInViaSSOSucceeded(responseBody))
        ret = true
      } else {
        dispatch(onLoggingInViaSSOFailed(responseBody))
      }
    }
  } catch (error) {
    dispatch(onLoggingInViaSSOFailed({
      message: error.toString()
    }))
  } finally {
    return ret
  }
}

export const actAs = ( token, recruiter='' ) => async dispatch => {
  dispatch( onActingAs() )
  let ret = false
  try {
    const res = await actAsApi( token, recruiter )
    if (res.ok) {
      if (res.obj.error_code === 0) {
        dispatch( onActingAsSuccess( { token: res.obj.token, acting: recruiter !== '' } ) )
        console.debug( { res } )
        ret = true
      } else {
        console.log( `Error with error code (${res.obj.error_code})` )
        dispatch( onActingAsFailed( {
          message: `Error with error code (${res.obj.error_code})`,
        } ) )
      }
    } else {
      console.log("Error with api call (ok)")
      dispatch( onActingAsFailed( {
        message: 'Error with error code (ok)',
      } ) )
    }
  } catch (err) {
    console.log( err )
    dispatch( onActingAsFailed( {
      message: err.toString(),
    } ) )
  }
  return ret
}

export const updateCandidateConsent = (email, password, hasConsent) => async dispatch => {
  try {
    const response = await updateExplicitConsent(email, password, hasConsent);
    if (response && response.ok && response.obj) {
      const responseBody = response.obj;
      console.log("responseBody: ", responseBody)
      if (response.obj.status === STATUS.success) {
        return responseBody.message?.has_consent;
      }
    }
    return false;
  } catch (err) {
    console.log("Error: ", err)
    return false;
  }
}